import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/gva_web/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// 英文
import enLocale from 'element-ui/lib/locale/lang/en';
var ENS = _objectSpread(_objectSpread({}, enLocale), {}, {
  module: {},
  route: {
    "dashboard": "Overview",
    "superAdmin": "System Management",
    "authority": "Role Management",
    "menu": "Menu Management",
    "api": "API Management",
    "dept": "Department",
    "user": "User Management",
    "group": "User Group",
    "sysDictionary": "Data Dictionary",
    "dictionaryDetail": "Data Dictionary Detail",
    "person": "Personal Info",
    "systemTools": "System Tools",
    "operation": "Operation Log",
    "autoCode": "Auto Code",
    "formCreate": "Form Builder",
    "report": "Report Background",
    "rmtool": "Reporting Platform",
    "rmparam": "Report Parameter",
    "rmparamtype": "Report Type",
    "rmparamrelatedata": "Parameter Value",
    "rmmenu": "Report Management",
    "toolUserConfig": "Platform User",
    "frontreport": "Dashbord&Report",
    "reportCreate": "TableauReportDemo",
    "reportcard": "Report Card",
    "reportcardDetail": "Report Detail",
    "ddp": "Master Data-Dealer",
    "ddpdealer": "Dealer Info",
    "ddpdealercode": "Dealer Code",
    "ddpdealergrade": "Dealer Grade",
    "workFlow": "WorkFlow Management",
    "classify": "Classify",
    "template": "Template",
    "about": "About Us",
    "filemanage": "File Management",
    "fileservice": "File Service",
    "filerole": "File Role Management",
    "datapermission": "Data Permission",
    "rtmmap": "RTM Map",
    "file": "File",
    "viewlog": "	Reporting Log",
    "formList": "Form Card",
    "formCard": "Form Card Detail",
    "formManage": "Form Management",
    "Form": "Form"
  },
  common: {
    "save": "Save",
    "cancel": "Cancel",
    "add": "Add",
    "edit": "Edit",
    "delete": "Delete",
    "confirm": "Confirm",
    "reset": "Reset",
    "search": "Search",
    "copy": "Copy",
    "batch_delete": "Batch Delete",
    "export": "Export",
    "import": "Import",
    "operation": "Operation",
    "success": "Success",
    "error": "Unknown error",
    "canceled": "Canceled",
    "yes": "Yes",
    "no": "No",
    "on": "On",
    "off": "Off",
    "enable": "Enable",
    "disable": "Disable",
    "delete_warning": "The operation will delete data permanently, continue?",
    "export_excel": "Export data to excel file, continue?"
  },
  layout: {
    "reset_password": "Reset password",
    "old_password": "Old passowrd",
    "old_password_required": "Please enter old password",
    "old_password_min": "6 characters at least",
    "new_password": "New password",
    "new_password_required": "Pleaase enter new password",
    "new_password_min": "6 characters at least",
    "confirm_password": "Confirm password",
    "confirm_password_min": "6 characters at least",
    "confirm_password_required": "Please enter the same new password",
    "confirm_password_valid": "The confirm password is not the same as new password",
    "logout": "Log out"
  },
  login: {
    "username": "User name",
    "password": "Password",
    "signin": "Sign in",
    "signin_upper": "SIGN IN",
    "username_tip": "Enter user name",
    "password_tip": "Enter password",
    "captcha_tip": "Enter captcha"
  },
  dashboard: {},
  authority: {
    "addRole": "New Role",
    "id": "Role ID",
    "name": "Role name",
    "parent": "Parent role",
    "operation": "Operation",
    "role_configuration": "Role Configuration",
    "role_menu": "Role Menu",
    "role_api": "Role Api",
    "resource_permission": "Resource Permission",
    "add_subrole": "Add sub-role",
    "enter_id": "Please enter role ID",
    "enter_name": "Please enter role name",
    "enter_parent": "Please enter parent role",
    "new_role": "New Role",
    "root_role": "Root role",
    "error_id": "Role ID can't be 0",
    "select_all": "Select all",
    "select_self": "This role",
    "select_recursive": "This role and sub-role"
  },
  menu: {
    "id": "Menu ID",
    "name": "Route name",
    "name_placeholder": "Uniqu English string",
    "name_required": "Please enter menu name",
    "path": "Route path",
    "add_parameter": "Add parameter",
    "path_placeholder": "Please enter chinese name of report",
    "visibility": "Whether to hide",
    "visibility_placeholder": "Please enter id",
    "parent": "Parent node",
    "parent_placeholder": "Please choose parent node id",
    "sort": "Sort",
    "sort_placeholder": "Please choose report type",
    "file_path": "File path",
    "file_path_placeholder": "Please choose report status",
    "file_path_required": "Please enter file path",
    "label": "Display name",
    "label_required": "Please enter display name",
    "icon": "Icon",
    "keep_alive": "KeepAlive",
    "keep_alive_placeholder": "Whether to cache the page",
    "operation": "Operation",
    "hide": "hide",
    "show": "show",
    "add": "New Root Menu",
    "root_menu": "Root Menu",
    "add_menu_parameter": "New menu parameters",
    "add_menu_parameter_tip": "The new menu needs to be configured in the role management before it can be used",
    "parameter_type": "Parameter Type",
    "parameter_key": "Parameter Key",
    "parameter_key_tip": "Please choose",
    "parameter_value": "Parameter Value"
  },
  api: {
    "search": "Search",
    "add": "Add API",
    "id": "ID",
    "path": "API path",
    "path_required": "Please enter API path",
    "group": "API group",
    "group_required": "Please enter API group",
    "description": "API description",
    "description_required": "Please enter API description",
    "request": "Request",
    "request_tip": "Please choose",
    "request_required": "Please choose request",
    "add_tip": "New Api needs to be confiuger permission in the role management before it can be used"
  },
  department: {
    'status': 'Status',
    'status_placeholder': "Department status",
    'sort': "Sort",
    'sort_required': 'Sort is required',
    'name': "Department name",
    'name_default': 'Major deparment',
    'parent_node': 'Parent node',
    'name_required': 'Department name is requred',
    'name_placeholder': "Please enter department name",
    'parent': "Parent department",
    'parent_required': 'Parent department is required',
    'manager': 'Manager',
    'manager_placeholder': 'Please enter manager',
    'telephone': 'Phone number',
    'telephone_format': 'Phone number is not correct',
    'telephone_placeholder': 'Please enter phone number',
    'email': 'Email',
    'email_format': 'Email format is not correct',
    'email_placeholder': 'Please enter email'
  },
  user: {
    'uuid': 'uuid',
    'name': "User name",
    'name_min': '6 character at least',
    'name_required': 'Please enter user name',
    'password': "Password",
    'password_min': '6 character at least',
    'password_required': 'Please enter password',
    'nick_name': "Nick name",
    'nick_name_required': 'Please enter nick name',
    'role_required': 'Please choose role',
    'avatar': 'Avatar',
    'avatar_tip': 'Choose from media liberary',
    'role': "User role",
    'sync': "AD Account Sync",
    'add': "New User",
    "CreatedAt": "Created Time"
  },
  usergroup: {
    'name_zh': "Chinese",
    "name_en": "English",
    "active": "Is acrive",
    "user_group_management": "user_group_management"
  },
  dictionary: {
    "date": "Date",
    "name_zh": "Chinese",
    "name_zh_placeholder": 'Please enter Chinese name',
    "name_zh_required": 'Please enter Chinese name',
    "name_en": "English",
    "name_en_placeholder": "Please enter English name",
    "name_en_required": "Please enter English name",
    "status": "Status",
    "status_on": "On",
    "status_off": "Off",
    "description": "Description",
    "description_placeholder": "Please enter description",
    "description_required": "Please enter description",
    "search_placeholder": "Search condition",
    "instruction": "Check code at 'utils/dictionary' for acquisition of dictionary values "
  },
  dictionary_detail: {
    "label": "Label",
    "label_required": "Please enter label",
    "label_placeholder": "Please enter label",
    "value": "Value",
    "value_required": "Please enter value",
    "value_placeholder": "Please enter value",
    "status": "Status",
    "sort_required": "Please enter sort field",
    "sort_placeholder": "Please enter sort field",
    "search_condition": "Search condition",
    "date": "Date",
    "sort": "Sort"
  },
  report_platform: {
    code: 'Platform code',
    code_placeholder: 'Please enter platform code',
    name: 'Platform name',
    name_placeholder: 'Please enter platform name',
    name_required: 'Please enter platform name',
    type: 'Platform type',
    type_placeholder: 'Please choose platform type',
    url: 'Platform address',
    url_placeholder: 'Please enter platform access address',
    url_required: 'Please enter platform access address',
    version: 'Platform version',
    version_placeholder: 'Please enter platform version',
    auth_url: 'Platform authorized address',
    auth_url_placeholder: 'Please enter platform authorized address',
    auth_url_required: 'Please enter platform authorized address',
    key: 'Access key',
    key_placeholder: 'Please enter access key',
    remark: 'Remark',
    remark_placeholder: 'Please enter remark'
  },
  report_platform_user: {
    platform_id: 'Platform',
    name: 'User name',
    name_placeholder: 'Please enter user name',
    password: 'Password',
    paddword_placeholder: 'Please enter password'
  },
  report_platform_parameter: {
    name: 'Parameter name',
    name_placeholder: 'Please enter name',
    key: 'Parameter key',
    key_placeholder: 'Please enter parameter key',
    type: 'Parameter type',
    type_placeholder: 'Please choose parameter type',
    sort: 'Sort',
    sort_placeholder: 'Please choose sort',
    remark: 'Remark',
    remark_placeholder: 'Please enter remark'
  },
  report_platform_type: {
    name: 'Name',
    status: 'State',
    type: 'Type',
    name_placeholder: 'Please enter name',
    status_placeholder: 'Please choose state',
    type_placeholder: 'Please choose type',
    buildin: 'Buildin',
    thrid_party: 'Thirdparty'
  },
  report_parameter_value: {
    code: 'Code',
    name: 'Name',
    type: 'Type',
    sort: 'Sort',
    code_placeholder: 'Please enter code',
    name_placeholder: 'Please enter name',
    type_placeholder: 'Please choose type',
    sort_placeholder: 'Please enter sort'
  },
  report_management: {
    name_zh: 'Chinese name',
    name_en: 'English name',
    url: 'Report address',
    status: 'Report status',
    type: "Report type",
    sort: "sort",
    group: 'Report grouping',
    platform: 'Platform',
    attribute: 'Report parameters',
    icon: 'Icon display',
    description: 'Report description',
    description_placeholder: "Please enter report description",
    image: 'Image',
    name_zh_placeholder: 'Please enter Chinese name',
    name_en_placeholder: 'Please enter English name',
    image_placeholder: 'upload image whoes size is smaller than 5MB',
    status_placeholder: '',
    group_placeholder: '',
    platform_placeholder: '',
    attribute_placeholder: '',
    image_format: 'The image can be in JPG/PNG format only',
    image_size: 'The image can not be larger than 5MB!',
    dir: 'Directory',
    tabType: 'Is Workbook',
    parent_node: "Parent node"
  },
  dealer_info: {
    no: 'No',
    name: 'Name',
    original_opening_since: 'Original since',
    dealer_no: 'Dealer No',
    abbreviation: 'Abbreviation',
    city: 'City',
    province: 'Province',
    build_type: 'Building Type',
    ci_status: 'CI Status',
    address: 'Address',
    investor: 'Investor',
    sales_advisor_desk_number: 'Sales Advisor Desk Number',
    plot_size: 'Plot Size',
    sr_size: 'SR Size',
    ws_size: 'WS Size',
    storage_size: 'Storage Size',
    sr_cars: 'SR Cars',
    bp: 'BP',
    ddb: 'DDB',
    standard: 'Standard',
    dianostic: 'Dianostic',
    paint: 'Paint',
    status_building_project: 'Status Building Project',
    investor_selection: 'Investor Selection',
    capacity: 'Capacity',
    pdd: 'PDD',
    permit_soc: 'Permit Soc',
    ceck_i: 'Ceck I',
    ceck_ii: 'Ceck II',
    ceck_iii: 'Ceck III',
    so_o: 'So O',
    grand_opening: 'Grand Opening',
    land_ownership: 'Land Ownership'
  },
  dealer_code: {
    code: 'Dealer Code',
    no: 'Dealer No',
    no_placeholder: 'Please enter dealer no',
    name: 'Dealer Name',
    name_placeholder: 'Please enter dealer name',
    group: 'Group',
    drp_2020: 'DRP2020',
    region: 'Region',
    pc_name: 'PC Name'
  },
  dealer_grade: {
    pc_code: 'PC Code',
    dealer_code: 'Dealer Code',
    dealer_code_placeholder: 'Please enter dealer code',
    pcnp_hk: 'PCNPHK',
    pcnp_hk_placeholder: 'Please enter PCNPHK',
    porsche_centre: 'Porsche Centre',
    dealer_group: 'Dealer Group',
    region: 'Region',
    sub_region: 'Sub Region',
    longitude: 'Longitude',
    latitude: 'Latitude',
    province: 'Province',
    address_cn: 'Address CN',
    porsche_centre_name_en: 'Porsche Centre Name EN',
    pc_name: 'PC Name',
    porsche_centre_name_cn: 'Porsche Centre Name CN',
    building_type: 'Building Type',
    soo_date: 'SOO Date',
    porsche_centre_short_name_cn: 'Porsche Centre Short Name CN',
    close_date: 'Close Date',
    start_date: 'Start Date',
    end_date: 'End Date',
    is_valid: 'Is Valid',
    create_date: 'Create Date',
    update_date: 'Update Date'
  },
  report_card: {
    authorized: "Authorized",
    recentBrowse: "Recent Browse",
    collected: "Favourite",
    platform: "Attribution platform",
    type: "Report type",
    group: "Report grouping",
    status: "Report status",
    description: "Summary description",
    thirdparty: "Thirdparty",
    self_design: "Self-developed",
    add_collected: 'Set as Favourite',
    save_as: 'Save as'
  },
  error404: {
    message: "sorry，please contact the system administer！"
  },
  fileRole: {
    role: "Role",
    permission: "Permission",
    user: "User",
    file: "File",
    role_blank_placeholder: "please enter role name"
  },
  fileManage: {
    "search": "Search",
    "add": "Add",
    "delete": "Delete",
    "name": "Name",
    "type": "Type",
    "refresh": "Refresh",
    "blank_placeholder": "please enter folder name",
    "operation": "Operation",
    "download": "Download",
    "createFolder": "Create Folder",
    "createDate": "Create Date",
    "size": "Size",
    "folderName": "Folder Name",
    "folderType": "Folder Type",
    upload: "Upload",
    createdBy: "Created By",
    settings: "Settings",
    max_size: "",
    image_format: "The file can only be DOC/DOCX/xls/xlsx/CSV format",
    "sizeLimit": "The image can not be larger than 500MB!"
  },
  user_group: {
    "role": "Role",
    "dealerCode": "Dealer Code",
    "dealerName": "Dealer Name",
    "dealerGroup": "Dealer Group",
    "region": "Region",
    "subRegion": "Sub Region",
    "userName": "User Name"
  },
  reporting_log: {
    username: "User Name",
    optime: "Operation Time",
    opdesc: "Operation Description",
    opobj: "Operation Object",
    // 操作类型
    open_card: "open report",
    favorite_card: "add to favourite",
    cancel_favorite_card: "remove from favourite",
    // 页面
    page_over_view: "overview",
    page_report_card: "Report Card",
    // 页面区域
    page_area_recent_browse: "Recent Browse",
    page_area_favourite: "Favourite",
    // 导出文件名
    export_file_name: 'reporting_log',
    search: {
      from: "From",
      to: "To",
      user: "User",
      opdesc: "Operation Desc.",
      opobj: "Operation Object"
    }
  },
  form_manage: {
    name: 'name',
    description: 'description',
    data: 'data'
  }
});
export default ENS;