var _import = require("./_import"); //获取组件的方法
var _asyncRouterHandle = function asyncRouterHandle(asyncRouter) {
  asyncRouter.map(function (item) {
    if (item.component) {
      item.component = _import(item.component);
    } else {
      delete item['component'];
    }
    if (item.children) {
      _asyncRouterHandle(item.children);
    }
  });
};
export { _asyncRouterHandle as asyncRouterHandle };