import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/gva_web/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
var CNS = _objectSpread(_objectSpread({}, zhLocale), {}, {
  module: {
    management: '能力管理',
    display: '能力展示'
  },
  route: {
    "dashboard": "平台监控",
    "superAdmin": "系统管理",
    "authority": "角色管理",
    "menu": "菜单管理",
    "api": "API管理",
    "dept": "组织架构",
    "user": "用户管理",
    "group": "用户组管理",
    "sysDictionary": "数据字典",
    "dictionaryDetail": "字典详情",
    "person": "个人信息",
    "systemTools": "系统工具",
    "operation": "操作历史",
    "autoCode": "代码生成",
    "formCreate": "表单生成器",
    "report": "报表管理后台",
    "rmtool": "报表平台",
    "rmparam": "报表参数",
    "rmparamtype": "报表参数类型",
    "rmparamrelatedata": "报表参数值",
    "rmmenu": "报表管理",
    "toolUserConfig": "报表平台账户管理",
    "frontreport": "前端报表",
    "reportCreate": "TableauReportDemo",
    "reportcard": "reportcard",
    "reportcardDetail": "报表详情",
    "ddp": "主数据-经销商",
    "ddpdealer": "经销商基础信息",
    "ddpdealercode": "经销商编码扩展",
    "ddpdealergrade": "经销商等级扩展",
    "about": "关于我们",
    "rtmmap": "地图",
    "filerole": "File Role",
    "file": "File",
    "viewlog": "	Reporting Log",
    "formList": "Form Card",
    "formCard": "Form Card 详情",
    "formManage": "Form 管理",
    "Form": "Form 表单"
  },
  place: {
    Beijing: '北京',
    Tianjin: '天津',
    Shanghai: '上海',
    Chongqing: '重庆',
    Hebei: '河北',
    Henan: '河南',
    Yunnan: '云南',
    Liaoning: '辽宁',
    Heilongjiang: '黑龙江',
    Hunan: '湖南',
    Anhui: '安徽',
    Shandong: '山东',
    Xinjiang: '新疆',
    Jiangsu: '江苏',
    Zhejiang: '浙江',
    Jiangxi: '江西',
    Hubei: '湖北',
    Guangxi: '广西',
    Gansu: '甘肃',
    Shanxi: '山西',
    InnerMongoria: '内蒙古',
    Shaanxi: '陕西',
    Jilin: '吉林',
    Fujian: '福建',
    Guizhou: '贵州',
    Guangdong: '广东',
    Qinghai: '青海',
    Tibet: '西藏',
    Sichuan: '四川',
    Ningxia: '宁夏',
    Hainan: '海南',
    Taiwan: '台湾',
    HongKong: '香港',
    Macao: '澳门',
    SouthChinaSeaIslands: '南海诸岛'
  },
  languageSelect: {
    language: '简体中文',
    success: '切换成简体中文了',
    selection: '简体中文'
  },
  time: {
    days: '天',
    hour: '小时',
    minute: '分钟',
    second: '秒',
    day: '天',
    hours: '小时',
    minutes: '分钟',
    seconds: '秒'
  },
  common: {},
  enum: {},
  navbar: {},
  operation: {},
  reporting_log: {
    username: "User Name",
    optime: "Operation Time",
    opdesc: "Operation Description",
    opobj: "Operation Object",
    // 操作类型
    open_card: "打开报表",
    favorite_card: "关注报表",
    cancel_favorite_card: "取消关注报表",
    // 页面
    page_over_view: "Overview",
    page_report_card: "Report Card",
    // 页面区域
    page_area_recent_browse: "Recent Browse",
    page_area_favourite: "Favourite",
    search: {
      from: "From",
      to: "To",
      user: "User",
      opdesc: "Operation Desc.",
      opobj: "Operation Object"
    }
  },
  form_manage: {
    name: '名称',
    description: '描述',
    data: '数据'
  }
});
export default CNS;