import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.to-string";
// 权限按钮展示指令
import { store } from '@/store/index';
var userInfo = store.getters['user/userInfo'];
export var auth = function auth(Vue) {
  Vue.directive('auth', {
    // 当被绑定的元素插入到 DOM 中时……
    bind: function bind(el, binding) {
      var type = "";
      switch (Object.prototype.toString.call(binding.value)) {
        case "[object Array]":
          type = "Array";
          break;
        case "[object String]":
          type = "String";
          break;
        case "[object Number]":
          type = "Number";
          break;
        default:
          type = "";
          break;
      }
      if (type === "") {
        /* eslint-disable */
        console.error("v-auth必须是Array,Number,String属性,暂不支持其他属性");
        /* eslint-enable */
        return;
      }
      var waitUse = binding.value.toString().split(",");
      var flag = waitUse.some(function (item) {
        return item == userInfo.authorityId;
      });
      if (binding.modifiers.not) {
        flag = !flag;
      }
      if (!flag) {
        el.style.display = 'none';
      }
    }
  });
};