import Vue from 'vue';
import Vuex from 'vuex';
//import VuexPersistence from 'vuex-persist'

import { user } from "@/store/module/user";
import { router } from "@/store/module/router";
import { dictionary } from "@/store/module/dictionary";
Vue.use(Vuex);

// const vuexLocal = new VuexPersistence({
//     storage: window.localStorage,
//     modules: ['user']
// })
export var store = new Vuex.Store({
  modules: {
    user: user,
    router: router,
    dictionary: dictionary
  }
  //plugins: [vuexLocal.plugin]
});