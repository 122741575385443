export default {
  name: "DynamicTable",
  data: function data() {
    return {
      tableData: [{
        name: "张三",
        id: 1
      }],
      tableColumns: [{
        label: "名称",
        attr: "name"
      }, {
        label: "ID",
        attr: "id"
      }]
    };
  },
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    columns: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  watch: {
    data: {
      handler: function handler(data) {
        if (data) {
          this.tableData = data;
        }
      },
      deep: true,
      immediate: true
    }
  }
};