var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData
    }
  }, [_vm._l(_vm.tableColumns, function (col) {
    return [_c("el-table-column", {
      key: col.attr,
      attrs: {
        label: col.label,
        prop: col.attr
      }
    })];
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };