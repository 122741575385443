import "core-js/modules/es6.number.constructor";
export default {
  name: 'timeLine',
  props: {
    timeData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 播放间隔
    timeGap: {
      type: Number,
      default: 1000
    },
    // 间隔显示的原点
    nodeGap: {
      type: Number,
      default: 10
    }
  },
  data: function data() {
    return {
      current: 0,
      playStatus: false,
      timer: null
    };
  },
  computed: {
    timeDataComputed: function timeDataComputed() {
      return this.timeData;
    }
  },
  watch: {
    timeDataComputed: {
      handler: function handler(n) {
        if (n && n.length) {
          var currentVal = this.timeData[0].value;
          this.$emit('change', currentVal);
        }
      },
      deep: true
    }
  },
  methods: {
    singleClick: function singleClick(index) {
      this.current = index;
      var currentVal = this.timeData[this.current].value;
      this.$emit('change', currentVal);
      if (this.playStatus) {
        this.playClick();
      }
    },
    leftClick: function leftClick() {
      if (this.current > 0) {
        this.current -= 1;
      } else {
        this.current = this.timeData.length - 1;
      }
      var currentVal = this.timeData[this.current].value;
      this.$emit('change', currentVal);
    },
    rightClick: function rightClick() {
      if (this.current < this.timeData.length - 1) {
        this.current += 1;
      } else {
        this.current = 0;
      }
      var currentVal = this.timeData[this.current].value;
      this.$emit('change', currentVal);
    },
    playClick: function playClick() {
      var _this = this;
      if (this.playStatus) {
        // 暂停
        clearInterval(this.timer);
      } else {
        // 播放
        this.timer = setInterval(function () {
          _this.rightClick();
        }, this.timeGap);
      }
      this.playStatus = !this.playStatus;
    }
  }
};