import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.constructor";
//获取cookie
export function getCookie(name) {
  var arr,
    reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg)) return unescape(arr[2]);else return null;
}

//设置cookie,增加到vue实例方便全局调用
export function setCookie(c_name, value, expiredays) {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  // exdate.setMinutes(exdate.getDate() + expiredays);
  document.cookie = c_name + "=" + escape(value) + (expiredays == null ? "" : ";expires=" + exdate.toGMTString());
}
export function clearAllCookie() {
  var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
  // console.log(keys);
  if (keys) {
    for (var i = keys.length; i--;) document.cookie = keys[i] + '=""; Max-Age=0; path="/";domain=' + window.location.host;
    // console.log(document.cookie);
  }
}

//删除cookie
export function delCookie(name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  if (cval != null)
    // document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
    document.cookie = name + '=' + cval + '; Max-Age=0; path=/';
}