export default {
  install: function install(Vue, options) {
    Vue.prototype.global = {
      filePermission: {
        1: ['Download'],
        2: ['Upload'],
        3: ['Download', 'Upload'],
        4: ['Delete'],
        5: ['Download', 'Delete'],
        6: ['Upload', 'Delete'],
        7: ['Download', 'Upload', 'Delete']
      }
    };
  }
};