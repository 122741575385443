import "/gva_web/node_modules/core-js/modules/es6.array.iterator.js";
import "/gva_web/node_modules/core-js/modules/es6.promise.js";
import "/gva_web/node_modules/core-js/modules/es6.object.assign.js";
import "/gva_web/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import constant from "./const/const";
//import resizer from 'iframe-resizer'
import App from "./App.vue";
// 引入element
import ElementUI from 'element-ui';
import "./assets/fonts/porsche.css";
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets

import "./style/element-variables.scss";

// 引入初始化样式
import '@/style/main.scss';
import '@/style/base.scss';

// 全局配置elementui的dialog不能通过点击遮罩层关闭
ElementUI.Dialog.props.closeOnClickModal.default = false;
Vue.use(ElementUI);
// 引入封装的router
import router from '@/router/index';

// canvas背景插件
// import vueParticleLine from 'vue-particle-line'
// import 'vue-particle-line/dist/vue-particle-line.css'
// Vue.use(vueParticleLine)

// time line css
// import '../node_modules/timeline-vuejs/dist/timeline-vuejs.css'
import _i18n from "./lang"; // internationalization

Vue.use(ElementUI, {
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});
import '@/permission';
import { store } from '@/store/index';
Vue.config.productionTip = false;

// 路由守卫
import Bus from '@/utils/bus.js';
import enlang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
Vue.use(Bus);
import { auth } from '@/directive/auth';
// 按钮权限指令
auth(Vue);
import uploader from 'vue-simple-uploader';
Vue.use(uploader);
new Vue({
  render: function render(h) {
    return h(App);
  },
  router: router,
  store: store,
  i18n: _i18n
}).$mount('#app');
locale.use(enlang);
//引入echarts
// import echarts from 'echarts'
// Vue.prototype.$echarts = echarts;

import BasicLayout from '@/view/layout/BasicLayout';
Vue.component('BasicLayout', BasicLayout);
import timeLine from "@/components/timeLine";
Vue.component("timeLine", timeLine);
import DynamicTable from "@/components/DynamicTable";
Vue.component("DynamicTable", DynamicTable);

//parseTime download
import { resetForm, addDateRange, selectDictLabel, selectItemsLabel } from '@/utils/costum';
import moment from "moment";
Vue.prototype.$moment = moment;
Vue.prototype.resetForm = resetForm;
Vue.prototype.addDateRange = addDateRange;
Vue.prototype.selectDictLabel = selectDictLabel;
Vue.prototype.selectItemsLabel = selectItemsLabel;
Vue.prototype.url = process.env.VUE_APP_BASE_URL;
Vue.prototype.msgSuccess = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'success'
  });
};
Vue.prototype.msgError = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'error'
  });
};
Vue.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
};
import "./icons"; // icon
// import VueParticles from 'vue-particles'
// Vue.use(VueParticles)

import axios from "axios";
Vue.prototype.$axios = axios;
Vue.use(constant);

// import 'vue-tableau'

// import 'form-making'