import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
var originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(function (err) {
    return err;
  });
};
var baseRouters = [/**修改首页跳转的问题 */
{
  path: '/',
  name: 'index',
  redirect: "./layout/dashboard"
}, {
  path: '/login',
  name: 'login',
  component: function component() {
    return import('@/view/login/login.vue');
  }
}, {
  path: '/register',
  name: 'register',
  component: function component() {
    return import('@/view/login/register.vue');
  }
}];

// 需要通过后台数据来生成的组件
var createRouter = function createRouter() {
  return new Router({
    //mode: 'history', // require service support
    // base:'/',
    routes: baseRouters
  });
};
var router = createRouter();
// console.log('router  ',router);
export default router;