import axios from 'axios'; // 引入axios
import { Loading, Message } from 'element-ui';
import { store } from '@/store/index';
import { getCookie, setCookie } from "@/utils/cookie";
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 99999
});
var acitveAxios = 0;
var loadingInstance;
var timer;
var showLoading = function showLoading() {
  acitveAxios++;
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(function () {
    if (acitveAxios > 0) {
      loadingInstance = Loading.service({
        fullscreen: true
      });
    }
  }, 400);
};
var closeLoading = function closeLoading() {
  acitveAxios--;
  if (acitveAxios <= 0) {
    clearTimeout(timer);
    loadingInstance && loadingInstance.close();
  }
};
//http request 拦截器
service.interceptors.request.use(function (config) {
  showLoading();
  // const token = store.getters['user/token']
  var user = store.getters['user/userInfo'];
  var token = getCookie("token");
  var userId = getCookie("userId");
  var uuid = getCookie("uuid");
  var nickName = getCookie("nickName");
  var headerImg = getCookie("headerImg");
  var userName = getCookie("userName");
  var isAdmin = getCookie("isAdmin");
  if (uuid !== "") {
    if (user.uuid != uuid) {
      user.uuid = uuid;
    }
  }
  if (user.nickName == "" && nickName !== user.nickName) {
    user.nickName = nickName;
  }
  if (user.headerImg == "" && headerImg !== user.headerImg) {
    user.headerImg = headerImg;
  }
  if (user.userName == "" && userName !== user.userName) {
    user.userName = userName;
  }
  if (user.isAdmin == "" && isAdmin !== user.isAdmin) {
    user.isAdmin = isAdmin == "true" ? true : false;
  }
  if (user.ID == 0 && user.ID != userId) {
    user.ID = userId;
  }
  store.user = user;
  config.data = JSON.stringify(config.data);
  if (token == null && userId == null) {
    // console.log('url ' + logouturl)
    // window.location.href = logouturl;
  }
  config.headers = {
    'Content-Type': 'application/json',
    'x-token': token,
    'x-user-id': userId,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type',
    'Access-Control-Allow-Methods': 'PUT,POST,GET,OPTIONS,DELETE'
  };
  return config;
}, function (error) {
  closeLoading();
  Message({
    showClose: true,
    message: error,
    type: 'error'
  });
  return Promise.reject(error);
});

//http response 拦截器
service.interceptors.response.use(function (response) {
  closeLoading();
  if (response.headers["new-token"]) {
    // console.log('refresh token ');
    // setCookie("token",response.headers["new-token"],5)
  }
  if (response.data.code == 0 || response.headers.success === "true") {
    return response.data;
  } else {
    Message({
      showClose: true,
      message: response.data.msg || decodeURI(response.headers.msg),
      type: 'error'
    });
    if (response.data.data && response.data.data.reload) {
      store.commit('user/LoginOut');
    }
    return Promise.reject(response.data.msg);
  }
}, function (error) {
  closeLoading();
  Message({
    showClose: true,
    message: error,
    type: 'error'
  });
  return Promise.reject(error);
});
export default service;