import service from '@/utils/request';

// @Summary 用户登录
// @Produce  application/json
// @Param data body {username:"string",password:"string"}
// @Router /base/login [post]
export var login = function login(data) {
  return service({
    url: "/base/login",
    method: 'post',
    data: data
  });
};

// @Summary ppn登录
// @Produce  application/json
// @Param data body
// @Router /auth/saml [post]
export var auth = function auth(data) {
  return service({
    url: "/auth/saml",
    method: 'post',
    data: data
  });
};
// @Summary 获取验证码
// @Produce  application/json
// @Param data body {username:"string",password:"string"}
// @Router /base/captcha [post]
export var captcha = function captcha(data) {
  return service({
    url: "/base/captcha",
    method: 'post',
    data: data
  });
};

// @Summary 用户注册
// @Produce  application/json
// @Param data body {username:"string",password:"string"}
// @Router /base/resige [post]
export var register = function register(data) {
  return service({
    url: "/base/register",
    method: 'post',
    data: data
  });
};
// @Summary 修改密码
// @Produce  application/json
// @Param data body {username:"string",password:"string",newPassword:"string"}
// @Router /user/changePassword [post]
export var changePassword = function changePassword(data) {
  return service({
    url: "/user/changePassword",
    method: 'post',
    data: data
  });
};
export var getUserList = function getUserList(params) {
  return service({
    url: "/user/getUserList",
    method: 'get',
    params: params
  });
};
export var getDealerGroupUserList = function getDealerGroupUserList(params) {
  return service({
    url: "/user/getDealerGroupUserList",
    method: 'get',
    params: params
  });
};

// @Tags User
// @Summary 设置用户权限
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body api.SetUserAuth true "设置用户权限"
// @Success 200 {string} json "{"success":true,"data":{},"msg":"修改成功"}"
// @Router /user/setUserAuthority [post]
export var setUserAuthority = function setUserAuthority(data) {
  return service({
    url: "/user/setUserAuthority",
    method: 'post',
    data: data
  });
};

// @Tags SysUser
// @Summary 删除用户
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.SetUserAuth true "删除用户"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"修改成功"}"
// @Router /user/deleteUser [delete]
export var deleteUser = function deleteUser(data) {
  return service({
    url: "/user/deleteUser",
    method: 'delete',
    data: data
  });
};

// @Tags SysUser
// @Summary 设置用户信息
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysUser true "设置用户信息"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"修改成功"}"
// @Router /user/setUserInfo [put]
export var setUserInfo = function setUserInfo(data) {
  return service({
    url: "/user/setUserInfo",
    method: 'put',
    data: data
  });
};
export var syncADUsers = function syncADUsers(data) {
  return service({
    url: "/user/syncADUsers",
    method: 'post',
    data: data
  });
};
export var getGroupUserLstByGroupId = function getGroupUserLstByGroupId(data) {
  return service({
    url: "/user/getGroupUserLstByGroupId",
    method: 'post',
    data: data
  });
};
export var getNotGroupUserLstByGroupId = function getNotGroupUserLstByGroupId(data) {
  return service({
    url: "/user/getNotGroupUserLstByGroupId",
    method: 'post',
    data: data
  });
};
export var getAllUsers = function getAllUsers(data) {
  return service({
    url: "/user/getAllUsers",
    method: 'post',
    data: data
  });
};

// 查询用户列表
export function listUser(query) {
  return service({
    url: "/user/getAllUsers",
    method: 'post',
    params: query
  });
}

// @Tags SysUser
// @Summary 用id查询SysUser
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysUser true "用id查询SysUser"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /user/findSysUser [get]
export var findSysUser = function findSysUser(params) {
  return service({
    url: "/user/findSysUser",
    method: 'get',
    params: params
  });
};

// @Tags SysUser
// @Summary 更新SysUser
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysUser true "更新SysUser"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /user/updateSysUser [put]
export var updateSysUser = function updateSysUser(data) {
  return service({
    url: "/user/updateSysUser",
    method: 'put',
    data: data
  });
};